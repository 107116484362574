<template>
  <div>
    <form-base
      v-model="product"
      @handle-duplicate-error="handleDuplicateError"
      @update-form-status="updateFormBaseStatus"
    />
    <form-detail
      v-model="product"
    />
    <attachments-create
      ref="attachments"
      v-model="product"
      @update-files="val => product.document_files = val"
      @all-updated="handleAllUpdated"
    />

    <actions
      :buttons="actionsButton"
      @click="handleActionsButton"
    />
  </div>
</template>

<script>
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import productStoreModule from '@/views/product/productStoreModule'
import useProductOfficeCreate from '@/views/product-office/create/useProductOfficeCreate'
import FormBase from '@/views/product-office/create/FormBase.vue'
import FormDetail from '@/views/product-office/create/FormDetail.vue'
import Actions from '@core/components/fixed-button/Fixed-Right-Button.vue'
import AttachmentsCreate from '@/views/components/util/AttachmentsCreate.vue'

export default {
  components: {
    FormBase,
    FormDetail,
    AttachmentsCreate,
    Actions,
  },
  computed: {
    actionsButton() {
      const validActions = []
      validActions.push(this.ACTIONS_BUTTON.CREATE)
      return validActions
    },
  },
  created() {
    this.product.product_type = 'office'
    this.product.type = 'van-phong'
    this.product.currency = 'usd'
    if (this.$store.state.app.isDev) {
      this.fakeDataProductOffice()
    }
  },
  methods: {
    updateFormBaseStatus(statusMsg) {
      this.formBaseStatus = statusMsg
    },
    handleDuplicateError(statusMsg) {
      this.duplicateError = statusMsg
    },
    handleAllUpdated(files) {
      if (!this.isUploading) return
      this.product.document_files = files
      this.handleCreateProduct()
    },
    handleActionsButton(btn) {
      if (btn.value === 'create') {
        const pending = this.$refs.attachments.$refs.uploads.$refs.uploads.getQueuedFiles()
        if (pending && pending.length) {
          this.isUploading = true
          this.$refs.attachments.$refs.uploads.$refs.uploads.processQueue()
        } else {
          this.handleCreateProduct()
        }
      }
    },
    handleCreateProduct() {
      if (this.duplicateError) {
        this.$showNoti(this.duplicateError, 'warning')
        return
      }
      const errs = Array.isArray(this.formBaseStatus) ? this.formBaseStatus.filter(item => item) : [this.formBaseStatus].filter(item => item)
      if (errs && errs.length) {
        this.$showNoti(errs.join(', '), 'warning')
        return
      }
      if (!this.product.title || !this.product.content) {
        this.$showNoti('Cần cập nhật chi tiết trước khi tạo', 'warning')
        return
      }
      this.product.image = this.product.image || (this.product.document_files.length ? this.product.document_files[0] : {}).origin || ''
      this.$call(this.createProduct(this.product), true).then(({ id }) => {
        this.$router.push({ name: 'product-office-detail', params: { id } })
      })
    },
    getFormalityProduct() {
      return []
    },
  },
  setup() {
    const PRODUCT_STORE_MODULE_NAME = 'product'
    if (!store.hasModule(PRODUCT_STORE_MODULE_NAME)) store.registerModule(PRODUCT_STORE_MODULE_NAME, productStoreModule)
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_STORE_MODULE_NAME)
    })
    return {
      ...useProductOfficeCreate(),
    }
  },
}
</script>

<style lang="scss">
</style>
